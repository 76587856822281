/**
 * This is more or less just a collection of functions to interface with indexedDB.
 * Currently this doesnt require any state, no reason why it can't be a full fledged
 * class down the line though.
 * Few of these functions dont *need* to return a promise but since we use so much async
 * code it makes sense to make them all return a promise so that the developer can implement
 * these functions in a uniform way.
 */
export default class SktDb {
	constructor() {
		return new Promise(resolve => {
		
			const request = indexedDB.open('SKTindexedDB', 1)
			request.onupgradeneeded = (event) => {
				
				const db = event.target.result
				
				const dbBarcode = db.createObjectStore("barcode");
				const dbFailedPosts = db.createObjectStore("failedposts");
				const dbLocation = db.createObjectStore("location");
				const dbRoom = db.createObjectStore("room");
				const dbRoomBarcodeAlert = db.createObjectStore("room-barcode-alert");
				
				//dbRoomInventory uses an additional, complex key for ensuring clean data
				const dbRoomInventory = db.createObjectStore("room-inventory");
				dbRoomInventory.createIndex("unique-room-inventory", ['room_id', 'barcode_id', 'deleted']);
				
				console.log("IDB Built");
			}
			
			resolve()
		})
	}

	// Adds a singular record to an indexedDb table
	static add (record, table) {
		return new Promise(resolve => {
		  const request = indexedDB.open('SKTindexedDB', 1)
		  request.onsuccess = (event) => {
			const db = event.target.result
			const result = db.transaction([table], 'readwrite')
			  .objectStore(table)
			  .put(record, Number(record.id))

			result.onsuccess = () => resolve()
			result.onerror = () => console.log('Record failed to add.')
		  }
		})
	}

	// Removes record in table
	static remove (table, id) {
		return new Promise(resolve => {
		  const request = indexedDB.open('SKTindexedDB', 1)
		  request.onsuccess = (event) => {
			const db = event.target.result
			const request = db.transaction([table], 'readwrite')
			  .objectStore(table)
			  .delete(Number(id))

			request.onsuccess = () => {
			  console.log('Removed record in ' + table + ' of id: ' + id)
			  resolve()
			}
		  }
		})
	}

	// Returns record from table
	static getRecord (table, id) {
		try {
		   return new Promise(resolve => {
		  const request = indexedDB.open('SKTindexedDB', 1)
		  request.onsuccess = (event) => {
			const db = event.target.result
			const transaction = db.transaction([table])
			const objectStore = transaction.objectStore(table)
			const result = objectStore.get(Number(id))
			// console.log(result)
			// result.onerror = (error) => console.log(error)
			result.onsuccess = () => resolve(result.result)
		  }
		})
		} catch (error) {
		  console.error(error)
		  return null
		}

	}

	// Returns all records from given table
	static readAll (table) {
		console.log("Reading "+table);

		try {
		  return new Promise(resolve => {
			const request = indexedDB.open('SKTindexedDB', 1)
			request.onsuccess = (event) => {
			  const db = event.target.result
			  const transaction = db.transaction([table])
			  const objectStore = transaction.objectStore(table)
			  const result = objectStore.getAll()
			  
			  console.log("IDB Read All");
			  console.log(result);
			  // console.log(result)
			  result.onsuccess = () => resolve(result.result)
			}
		  })
		} catch (error) {
		 console.error(error)
		 return null
		}

	}

	// Get record with compound key
	static getWithCompoundKey (table, indexName, key) {
		return new Promise(resolve => {
		  const request = indexedDB.open('SKTindexedDB', 1)
		  request.onsuccess = function (event) {
			const db = event.target.result
			const transaction = db.transaction(table)
			const store = transaction.objectStore(table)
			const index = store.index(indexName)
			const request = index.get(IDBKeyRange.only(key))
			request.onsuccess = () => resolve(request.result)
		  }
		})
	}

	static async syncUp () {
		const failedPosts = await this.readAll('failedposts')
		if (failedPosts.length > 0) {
			for (let i = 0; i < failedPosts.length; i++) {
				console.log('About to sync up')
				this.uploadRecordtoServerFromIDB(failedPosts[i].method, failedPosts[i].url, failedPosts[i].formdata, failedPosts[i].id)
			}
		}
	}

	static async uploadRecordtoServerFromIDB (method, url, body, id) {
		console.log('Uploading to Server from IDB')
		const formData = new FormData()
		const bodyObj = JSON.parse(body)
		for (const key in bodyObj) {
		  formData.append(key, bodyObj[key])
		}

		const response = await fetch(url, { method: method, body: formData })
		if (!response.ok) {
		  throw Error('Response Failed')
		}

		const data = await response.json()
		if (data.status === 'success') {
		  await this.remove('failedposts', id)
		  await this.remove('barcode', id)
		  await this.remove('room-barcode-alert', id)
		  await this.remove('room-inventory', id)
		}

	}
}
