/* GENERAL USE JAVASCRIPT/JQUERY */
// The comments below this are used by eslint, the camelcase line can be removed after all snake case vars have been converted
/* global Quagga */
/* eslint-disable camelcase */
import PromisePolyfill from 'promise-polyfill'
import domAssignmentFunctions from './domAssignmentFunctions'
import domReadyFunction from './domReadyFunction'
import twoDigits from './twoDigits'
import getCookie from './getCookie'

if (!window.Promise) {
  window.Promise = PromisePolyfill
}

/*
Alert Quantity mod is used to determine when an item is in yellow alert.
IF red alert is simply below the threshold, the LOW_ALERT_QUANTITY_MOD
multiplied by the threshold is the yellow threshhold.
EG: Threshold = 100, modifier is 2, so yellow threshold becomes 200.
*/

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('/serviceWorker.js', {
        scope: '/'
      })
      .then(res => console.log('service worker registered'))
      .catch(err => console.log('service worker not registered', err))
  })
} else {
  console.log('service worker not supported in this browser')
}

// This just adds a method to the default date class so we can format dates for the db easier, the comment after stops eslint from flagging it
// eslint-disable-next-line no-extend-native
Date.prototype.toMysqlFormat = function () {
  return this.getUTCFullYear() + '-' + twoDigits(1 + this.getUTCMonth()) + '-' + twoDigits(this.getUTCDate()) + ' ' + twoDigits(this.getUTCHours()) + ':' + twoDigits(this.getUTCMinutes()) + ':' + twoDigits(this.getUTCSeconds())
}

// Dom Manipulation Section
domAssignmentFunctions()
domReadyFunction()
