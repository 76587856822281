import getCookie from './getCookie'
import SktDb from './sktDb';

export default async function buildSyncRequirements (syncData) {
  let items = await SktDb.readAll('room-inventory');
	
  const sync_requirements = []
  if (getCookie('sync_data') && items.length !== 0) {
	console.log("Partial Local Sync Started.");
	
    const old_cookie_data = JSON.parse(getCookie('sync_data'))
    for (let i = 0; i < syncData.length; i++) {
      if (syncData[i].last_updated !== old_cookie_data[i].last_updated) {
        sync_requirements.push({
          table: old_cookie_data[i].table_name,
          previous_updated: old_cookie_data[i].last_updated,
          current_updated: syncData[i].last_updated
        })
      }
    }
  } else {
	console.log("Full Sync Started.");
	
    for (let i = 0; i < syncData.length; i++) {
      if (syncData[i].last_updated) {
        sync_requirements.push({
          table: syncData[i].table_name,
          previous_updated: '2020-00-00 00:00:00',
          current_updated: syncData[i].last_updated
        })
      }
    }
  }
  if (sync_requirements === []) { console.log('No sync necessarry') }
  return sync_requirements
};
