import setUpSettingsCookies from "./setUpSettingsCookies";
import getCookie from "./getCookie";
import findGetParameter from "./findGetParameter";
import SktDb from "./sktDb";
import autocomplete from "./autocomplete";
import setUpLocationName from "./setUpLocationName";
import pageSpecificJS from "./pageSpecificJS";
import syncDown from "./syncDown";
import setLongLastingCookie from "./setLongLastingCookie";
import adjustDarkScreenTop from "./adjustDarkScreenTop";
import prepareInventoryReportPage from "./prepareInventoryReportPage";
import readBarcode from "./readBarcode";
import convertTimeToTimezone from "./convertTimeToTimezone";
import mainSearchExecute from "./mainSearchExecute";
import prepareItemsIndexPage from "./prepareItemsIndexPage";
import insertParam from "./insertParam";
import JSON2CSV from "./JSON2CSV";
import setUpLocationAndRoomSelection from "./setUpLocationAndRoomSelection";
import displayNotification from "./displayNotification";
import updateEditQuantityCard from "./updateEditQuantityCard";
import Reports from "./pages/reports";
import upcAPI, { isValidBarcode } from "./upcAPI";
import loginHandler from "./login";
import ItemSort from "./itemSort";
import paymentsHandler from "./paymentsHandler";
import prepareAlertsIndexPage from './prepareAlertsIndexPage';
import populateBarcodeInfo from './populateBarcodeInfo';
import renderTransferData from './renderTransferData';
import zxcvbn from './zxcvbn/zxcvbn';
// jQuery iffy imports
import handleHomeMenu from "./handleHomeMenu";
import tooltipHandler from "./tooltipHandler";
import isOnline from "./isOnline";
import smartLookUp from "./smartLookUp";
import {loadCamera, cameraOptions} from "./scanner/scanner";

// global
import {
  ADD,
  SUBTRACT,
  setItemQuantityAdjustment,
  itemQuantityAdjustment,
  isItemMenuExpanded,
  setIsItemMenuExpanded,
} from "./globals";
import handleBugReporting from "./handleBugReporting";
// let ReportsVar = new Reports()

// globals
// import {online} from './globals'
let online;

import countSKU from "./countSKU";
import handleEditPermissions from "./handleEditPermissions";
import roleHandler from "./roleHandler";
// TODO: Refactor this function by page path and a switch case

export default async function domReadyFunction() {
	
  $(document).ready(function () {
		const db = new SktDb();
		
		if(getCookie('show_tooltips') == ''){
			setLongLastingCookie('show_tooltips', true);
		}
		//core function
		document.onkeypress = function (e) {
			e = e || window.event;
			//console.log(e.keyCode);
			
			if(e.keyCode == 13){
				
				//this is an enter keycode, we want to check if we are currently working on a form. If we are, then we want to submit the form.
				if(
					document.activeElement.tagName == 'INPUT'
					|| document.activeElement.tagName == 'SELECT'
				){
					//since our focus is on a form related element, we want to submit the form
					e.preventDefault(); //prevent whatever was going to happen since we know we want to try and submit a form instead
					
					//multiple forms exists on each page, so we're going to check each one to see if it contains this input
					$("form").each(function(index) {
						if($(this).has(e.target).length){
							
							//we found the form and are submitting it!
							$(this).submit();
						}
					});
				}
				
			}
		};
		
		$('body').on('click', '#scanner-restart', async function(e){
			$('#barcode-scanned').val('');
			$('#scanner-instructions').html('Point your camera at the barcode');
			loadCamera();
			
		});
		
		$('body').on('click', '#reset-barcode-scanner', async function(e){
			delete window.cameraFavoriteId;
			
			$('#barcode-scanned').val('');
			$('#scanner-instructions').html('Point your camera at the barcode');
			
			loadCamera();
			
		});
		
		$('.testing-form').on('submit', async function(e){
			e.preventDefault();
			
			//let's get the toggle field
			let toggle_field = $(this).data('field');
			let data = new FormData();
			
			data.append('toggle', toggle_field)
			
			//alert('toggle: ' + toggle_field);
			//pass our request
			const request = await fetch("/onlinerequest/toggle-field", {
				method: "POST",
				body: data,
			});
			
			let response = await request.json();
		
			console.log(response);
			
			//force the reload
			location.reload();
		});

		$('#password').on('keyup', function(e){
			let user_inputs = [];
			let password = $(this).val();
			let response = zxcvbn(password, user_inputs);
			let strength = (response.score / 4) * 100;
			let color = 'red';
			
			if(strength == 100){
				color = 'green';
			}
			
			strength = strength + '%';
			
			$('#password-strength-bar').css({'width': strength, 'background-color': color});
		});
		
		//receipt system
		$('.close-receipt').click(function(e) {
			$('.receipt-lightbox.displayed').hide();    
			$('.receipt-lightbox.displayed').removeClass('displayed');    
		});
		$('.receipt-link').on('click', function(e){
			$(this).next().show();
			$(this).next().addClass('displayed'); 
		});
	  
	  window.setInterval(function() {
			//console.log('Running Verify Check');
			//if any of our key cookies expired, handle them.
			if(getCookie('refresh_exp') == '' && getCookie('userdetails') != ''){
				window.location.href = "/login";
				return;
			}
		}, 5000);
	  
    // Page Specific jQuery
    let path = window.location.pathname;

    switch (path) {
      // Place jQuery events under appropriate page to optimize loading times
      case "/settings":
        handleBugReporting();
        break;

      default:
        break;
    }

    // Login excempt functions
    var pathname = window.location.pathname;

    if (pathname !== "/login") {
      countSKU();
    }
    if (pathname == "/users/edit-profile") {
      handleEditPermissions();
    }

    // Global jQuery

    if (pathname == "/manage/users") {
      roleHandler();
    }
	
	if (pathname == "/manage/subscription") {
		paymentsHandler();
	}
	
    tooltipHandler(); 
    setUpSettingsCookies();
	if (pathname == "/settings") {
		cameraOptions();
	}

    // reload the inventory report with sorting
    $("#inventory-reports th").on("click", function (e) {
		if($(this).hasClass('noclick')){
			return false;
		}
		
		insertParam("sort", $(this).data("column"));
    });
	
	$(".alert_toggle").on("click", function (e) {
		let checked = $(this).children('.alert_opt').attr('checked');
		if(typeof(checked) == 'undefined'){
			$(this).children('.alert_opt').attr('checked', 'checked');
		}else{
			$(this).children('.alert_opt').removeAttr('checked');
		}
	});

    // reload the report page w/ sorting
    // $('#sorting tbody').on('click', 'th', function (e) {
    //   e.preventDefault()
    //   // console.log($(this)[0].outerText)
    //   let param = $(this)[0].outerText.slice(0, -1).toLowerCase().replace(' ', '_')

    //   window.localStorage.setItem('report_sorting', param)
    //   reloadTable()

    // })

    // set-up some standards for running Audit mode
    let currentLocation = "";

    if (getCookie("active_location")) {
      currentLocation = JSON.parse(getCookie("active_location"));
    }
    const currentMode = findGetParameter("mode");
    const screenWidth = document.documentElement.clientWidth;

    /*******
     *** If we are in audit mode, we need to force the user to select a location.
     *** We do this by checking for a location cookie and checking the mode.
     ***
     *** If we have a location and mode, then we want to set a few things in motion
     ********/
    $(".dark-screen-top, #location-select-card").hide(); // keep data hidden until we need it
    if (
      (currentLocation.type !== "room" ||
        typeof currentLocation.name === "undefined") &&
      currentMode === "audit"
    ) {
      $(".dark-screen-top, #location-select-card").show();
    }

    // New Changes
    // if (currentLocation.type === 'room' && currentMode === 'audit') {
    if (screenWidth < 951 && pathname == "/items") {
      $(".mobile-search").addClass("mobile-visible");
      $("#mobile-autocomplete-search").focus();
    } else {
      $("#desktop-autocomplete-search").focus();
    }
    // }

    $("#location").on("change", () => {
      if (screenWidth < 951 && pathname == "/items") {
        $(".mobile-search").addClass("mobile-visible");
        $("#mobile-autocomplete-search").focus();
      } else {
        $("#desktop-autocomplete-search").focus();
      }
	  
	  const mode = findGetParameter("mode");
	  if(mode == 'audit'){
		const $newVal = $("#location").val();
        const type = $("#location").find(":selected").data("type");
		if(type == 'location' || $newVal == 'all'){
			const notification = {
				status: "failed",
				message: "You've accessed a location while in audit mode. We do not recommend proceeding with this changes as it is prone to user error.",
			};
			console.log(notification);
			displayNotification(notification);
		}else{
			//continue
		}
	}
	  
    });
	
	$('.preserve-data-toggled').on('click', async function(e){
		//create a form
		let data = new FormData();
		
		//append our data
		data.append('global_client_id', window.user.global_client_id);
		if (getCookie("preserve-data") === "true") {
			data.append('preserve', 'false');
		}else{
			data.append('preserve', 'true');
		}
		
		//pass our request
		const request = await fetch("/onlinerequest/preserve-trial-data", {
			method: "POST",
			body: data,
		});
		
		//check the response
		let response = await request.json();
		
		console.log(response);
	});
	
	$('#other').on('click', function(e){
		if($(this).is(':checked')){
			$('#other_more').show();
		}else{
			$('#other_more').hide();
		}
	})
	$('#competitor_solution').on('click', function(e){
		if($(this).is(':checked')){
			$('#competitor_name').show();
		}else{
			$('#competitor_name').hide();
		}
	})
	

    if (getCookie("is_tutorial_active") === "true") {
      $(".tutorial-display-element").show();
    }
    
	//online = navigator.onLine;
    navigator.serviceWorker.ready.then(async () => {
      let barcodes = await SktDb.readAll("barcode");
      barcodes = barcodes.filter((item) => item.live == true);

		autocomplete(document.getElementById("product-name"), barcodes);
		
      /*autocomplete(
        document.getElementById("barcode-input-form-field"),
        barcodes
      );*/
      autocomplete(
        document.getElementById("desktop-autocomplete-search"),
        barcodes
      );
      autocomplete(
        document.getElementById("mobile-autocomplete-search"),
        barcodes
      );

      if ($("#location").length || $("#location-options").length) {
        setUpLocationName();
      }

      pageSpecificJS();
	  
		online = await isOnline();
		if (online) {
			await SktDb.syncUp();
			const windowLoc = $(location).attr("pathname"); // jquery format to get window.location.pathname
			const now = Math.round(Date.now() / 1000);
			
			// TODO make a whitelist array to check instead of the logic in this
			if (
				windowLoc !== "/login" 
				&& windowLoc !== "/password-reset" 
				&& windowLoc !== "/recover-company-id" 
				&& !windowLoc.includes("sign-up")
			){
				if(!getCookie("refresh_exp") || parseInt(getCookie("refresh_exp")) < now){
					window.location.href = "/login";
				}
			}else{
				
			}
			
			if(windowLoc !== "/login" 
			&& windowLoc !== "/password-reset" 
			&& windowLoc !== "/recover-company-id"
			&& !windowLoc.includes("sign-up")){
				await syncDown();
			}
			
			let failedposts = await SktDb.readAll('failedposts');
			console.log(failedposts);
			if(failedposts.length > 0){
				$("#sync-status .sync-status-option").removeClass('show');
				$(".sync-count").html(failedposts.length);
				$("#sync-status .sync-status-option.unsynced").addClass('show');
				return false;
			}else{
				$(".sync-count").html(0);
				$("#sync-status .sync-status-option").removeClass('show');
				$("#sync-status .sync-status-option.synced").addClass('show');
			}
		}else{
			let failedposts = await SktDb.readAll('failedposts');
			console.log(failedposts);
			if(failedposts.length > 0){
				$("#sync-status .sync-status-option").removeClass('show');
				$(".sync-count").html(failedposts.length);
				$("#sync-status .sync-status-option.unsynced").addClass('show');
			}else{
				$(".sync-count").html(0);
				$("#sync-status .sync-status-option").removeClass('show');
				$("#sync-status .sync-status-option.offline").addClass('show');
			}
		}
		
		
		if (getCookie("userdetails")) {
			//$("#username").html(JSON.parse(getCookie("userdetails")).name);
			$("#client-name").html(JSON.parse(getCookie("userdetails")).clientName);
		}

		
    });
	
	//set a favorite camera for a device
	$('#scanner-overlay').on("submit", ".setCamera", async (e) => {
		e.preventDefault();
		console.log(e.currentTarget);
		
		//create a form
		let data = new FormData(e.currentTarget);
		window.cameraFavoriteId = data.get('camera_id'); //set the favorite
		
		//pass our request if we are saving it
		if(document.getElementById('remember_camera').checked) {
			const request = await fetch("/onlinerequest/set-camera", {
				method: "POST",
				body: data,
			});
		}
		
		loadCamera(); //load the camera
	});
	
	$('#manageCameras').on("submit", ".setCamera", async (e) => {
		e.preventDefault();
		console.log(e.currentTarget);
		
		//create a form
		let data = new FormData(e.currentTarget);
		
		//pass our request
		const request = await fetch("/onlinerequest/set-camera", {
			method: "POST",
			body: data,
		});
		
		location.reload();
	});

    // Handle only barcode printing

    $("#print-barcode").on("click", async (e) => {
		
		  let sku = $("#barcode-input-form-field").val();
		  //let header_text = "SK&T - 720.851.9180";
		  let product_name = $("#product_name").val();
		  //let product_description = $("#product_description").val();
		  console.log(sku);
		  
		  let barcode_label_size = JSON.parse(getCookie("usersettings")).barcode_default_size;
		  console.log(barcode_label_size);
		  console.log(JSON.parse(getCookie("usersettings")).barcode_default_size);
		  
		  

		  // Limit product name to 90 Chars
		  if (product_name.length > 90) {
			product_name = product_name.slice(product_name.length - 90, -1);
		  }
		  let body_object = new FormData();

		  body_object.append("barcode", sku);
		  //body_object.append("header_text", header_text);
		  body_object.append("product_name", product_name);
		  //body_object.append("product_description", product_description);
		  
		  body_object.append("barcode_label_size", barcode_label_size);

		  let upc = isValidBarcode(sku);
		  console.log(upc);
		  // return;
		  if (upc) {
			// valid upc/ean barcode
			// body_object.append("w", "250");
			// body_object.append("h", "75");

			/*
			body_object.append("sf", "1");
			body_object.append("ts", "8");
			body_object.append("pb", "10");
			body_object.append("th", "7");
			body_object.append("sy", "0.40");
			body_object.append("pt", "5");
			body_object.append("pl", "35%"); */
			body_object.append("type", "upc-a");
		  } else {
			// default to code-128
			/*
			body_object.append("sf", "1");
			body_object.append("ts", "8");
			body_object.append("pb", "10");
			body_object.append("th", "7");
			body_object.append("sy", "0.40");
			body_object.append("pt", "5");
			body_object.append("pl", "45%"); */
			body_object.append("type", "code-128");
		  }
		
			let req = await fetch("/api/generate-barcode", {
				method: "POST",
				body: body_object,
			}).catch((e) => {
				console.log(e);
			});

			let res = await req.json();

			console.log(res);
		

      // $('#printed-barcode').append(res);
      let popup = window.open();

      // let header = `<div><p>${header_text}</p><p>${product_name}</p><p>${product_description}</p></div>`;
      // popup.document.write(header + res);
      popup.document.write(res);

      popup.focus(); //required for IE
      // popup.onload = function () {
      //   JsBarcode('#printed-barcode', 'test');
      // };
      // popup.onbeforeprint = () => {
      //   JsBarcode('#printed-barcode', 'test');
      // };

      popup.print();
      //popup.close();

      // `<script src="https://cdnjs.cloudflare.com/ajax/libs/jsbarcode/3.11.5/JsBarcode.all.min.js" integrity="sha512-QEAheCz+x/VkKtxeGoDq6nsGyzTx/0LMINTgQjqZ0h3+NjP+bCsPYz3hn0HnBkGmkIFSr7QcEZT+KyEM7lbLPQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script><svg id="printed-barcode"></svg><script>JsBarcode("#printed-barcode", ${sku});</script>`
      // let pwin = window.open(
      //   document.getElementById('printed-barcode').src,
      //   '_blank'
      // );
      // pwin.onload = function () {
      //   JsBarcode('#printed-barcode', 'test');
      //   window.print();
      // };
    });

    // Handle printing, show barcodes on print.
    $("#print").on("click", async (e) => {
      e.preventDefault();
      // Wait for dom to be ready before initiating print. Hangup at .preventDefault is causing lag.
      $(async () => {
        window.onbeforeprint = (e) => {
          console.log("test");
          // $('#main_row').append('<th class="hide">Barcode</th>');
		  
		  //JsBarcode("#soletest", "hello");

          var i = 0;
          var t = document.getElementById("inventory_table");
		  
		  //`<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.5/dist/barcodes/JsBarcode.code128.min.js" integrity="sha512-QEAheCz+x/VkKtxeGoDq6nsGyzTx/0LMINTgQjqZ0h3+NjP+bCsPYz3hn0HnBkGmkIFSr7QcEZT+KyEM7lbLPQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>`
		  
          $("#inventory_table tr").each(function () {
            var val1 = $(t.rows[i].cells[0]).text();
            var val1_trim = val1.replace(/\s/g, "");
			var val_id = "#barcode"+val1_trim;
			var trim_val_id = val_id.replace(/\s/g, "");

            try {
              //console.log(typeof val1);
              // $(t.rows[i]).append(`<td><svg id="${val1}"></svg></td>`);
			  /*
              JsBarcode("#" + val1, val1, {
                width: 2,
              });
			  */
			  JsBarcode(trim_val_id, val1_trim, {
                width: 2,
              });
            } catch (error) {
              console.log(`${val1} cannot be made into a barcode`);
              console.error(error);
            }
            i++;
          });

          $(".hide").show();
          $(".nohide").hide();
        };

        window.onafterprint = (e) => {
          $(".hide").hide();
          $(".nohide").show();
        };
        window.print();
      });
    });

    if (
      $(".expand-item-menu-btn")[0] &&
      getCookie("is_tutorial_active") === "true"
    ) {
      adjustDarkScreenTop();
      $(".dark-screen").show();
      $("body").addClass("body-no-scroll");
      $(
        ".mobile-expand-item-container, .desktop-expand-item-container, #add-item-button, #scan-item-button, #desktop-scan-item-btn, #desktop-create-item-btn"
      ).addClass("red-tutorial-ring");
    }

    $("#filter-container #room-select").on("change", () => {
      const newVal = $("#filter-container #room-select")[0].selectedIndex;
      $("#page-name #location option")
        .eq(Number(newVal))
        .prop("selected", true);
      $("#page-name #location").trigger("change");
    });
	
	
	
	$('#hide-trial').on('click', async function(e){
		$('#free_trial_prompt').removeClass('show');
		$('#free_trial_show').addClass('show');
		
		const request = await fetch("/onlinerequest/hide-trial", {
			method: "POST",
		});
	});
	
	$('#free_trial_show').on('click', function(e){
		$('#free_trial_prompt').addClass('show');
		$('#free_trial_show').removeClass('show');
	});
	

    $("#page-name #location").on("change", async () => {
	  window.scrollTo(0, 0);
      // update sku on change
      // countSKU();
      const windowLoc = $(location).attr("pathname");
      const $newVal = $("#page-name #location").val();
      const type = $(this).find(":selected").data("type");
      let cookieBody = {
        id: "all",
      };

      if ($newVal !== "all") {
        // using the term 'place' since place can be either a room or location in this function, console log 'place' to see what I mean
        const place = await SktDb.getRecord(type.toLowerCase(), $newVal);
        place.type = type.toLowerCase();
        place.selectIndex = $("#page-name #location")[0].selectedIndex;
        cookieBody = place;
      }
      setLongLastingCookie("active_location", cookieBody);

      // Run page specific logic and rendering
		if (windowLoc === "/items"){
			prepareItemsIndexPage();
		}
      if (windowLoc === "/alerts") prepareAlertsIndexPage();
      if (windowLoc === "/reports/inventory-report") {
        prepareInventoryReportPage();
      }
      // if (windowLoc === '/reports/archive-report') prepareArchiveReportPage()
    });

    $("#location-options").on("change", async () => {
      const windowLoc = $(location).attr("pathname");
      const $newVal = $("#location-options").val();
      const type = $("#location-options").find(":selected").data("type");
	  const mode = findGetParameter("mode");
		if(mode == 'audit'){
			if(type == 'location' || $newVal == 'all'){
				const notification = {
					status: "failed",
					message: "Can only audit a room. Cannot audit a location",
				};
				console.log(notification);
				displayNotification(notification);
				return;
			}else{
				//continue
				console.log("Updating to: "+$newVal);
				$("#location option").each(function() {
					if($newVal == $(this).val() && $(this).data('type') == 'room'){
						console.log('Found a match');
						$(this).attr('selected', true);
					}
				})
			}
		}
	  
      let cookieBody = {
        id: "all",
      };

      if ($newVal !== "all") {
        // using the term 'place' since place can be either a room or location in this function, console log 'place' to see what I mean
        const place = await SktDb.getRecord(type.toLowerCase(), $newVal);
        place.type = type.toLowerCase();
        place.selectIndex = $("#location-options")[0].selectedIndex;
        cookieBody = place;
      }
      setLongLastingCookie("active_location", cookieBody);

      // Run page specific logic and rendering
      if (windowLoc === "/items") prepareItemsIndexPage();
      if (windowLoc === "/alerts") prepareAlertsIndexPage();

      $(".dark-screen-top, #location-select-card").hide();

      if (screenWidth < 951 && windowLoc === "/items") {
        $(".mobile-search").addClass("mobile-visible");
        $("#mobile-autocomplete-search").focus();
      } else {
        $("#desktop-autocomplete-search").focus();
      }
    });

    $(".cookie-toggle").each((index, obj) => {
      const $el = $(".cookie-toggle").eq(index);
      const $cookie_name = $el.attr("data-cookie");
      if ($el.is('input[type="checkbox"]')) {
        if (getCookie($cookie_name) === "true") {
          $el.prop("checked", true);
        }
      }
    });

    $(".cookie-toggle").on("click", (e) => {
      const text = $(e.target).attr("data-cookie");
      const cookie = getCookie(text);
      setLongLastingCookie(text, cookie !== "true");
    });

    const setUpReports = async () => {
      Reports.registerEvents();
    };
    if ($("#reports-submit").length) setUpReports();

    if (sessionStorage.getItem("notification")) {
      const notification = JSON.parse(sessionStorage.getItem("notification"));
      displayNotification(notification);
      sessionStorage.removeItem("notification");
    }

    $(document).on(
      "click",
      ".notification-success span, .notification-failed span, .notification span",
      function () {
        $('.notification-flash').hide();
        $('.notification').hide();
      }
    );

    $("body").removeClass("preload");

    /********
     ** TURN SOMETHING INTO A LINK USING ARIA-LINK
     **/
    $(".js-link").on("click", function (e) {
      window.location.href = $(this).attr("aria-link");
    });

    if (!online) {
		$(".hide-when-offline").hide();
    }

    /********
     ** Generate the anticipated slug for this name
     **/
    $(".system__name").on("blur", function () {
      if (!$(".system__slug").val()) {
        let str = $(this).val();

        str = str.replace(/[^a-zA-Z ]/g, "");
        str = str.toLowerCase();
        str = str.replace(/\s+/g, "-");

        $(".system__slug").val(str);
      }
    });

    $("#skip-tutorial").on("click", () => {
      setLongLastingCookie("is_tutorial_active", false);
      location.reload();
    });

    $("#tutorial-scanner-close").on("click", () => {
      $("#tutorial-for-scanner").fadeOut("fast");
    });

    $("#tutorial-scanner-skip-tutorial").on("click", () => {
      $("#tutorial-for-scanner").fadeOut("fast");
      setLongLastingCookie("is_tutorial_active", false);
    });

    /**
     * Both of these buttons work the same for now because the page
     * they show up on is currently the last step in the tutorial.
     * In the future if this page stays the last tutorial page then
     * the buttons should be replaced with one button that says something
     * like "Finish tutorial"
     */
    $("#tutorial-add-item-skip, #tutorial-add-item-close").on("click", () => {
      setLongLastingCookie("is_tutorial_active", false);
      document.location = "/items";
    });

    $(".expand-item-menu-btn").on("click", function () {
      if (getCookie("is_tutorial_active") === "true") {
        $(".tutorial-box, .hidden-controls, #menu-items-tutorial").fadeToggle(
          "fast"
        );
        $(
          ".mobile-expand-item-container, .desktop-expand-item-container"
        ).toggleClass("red-tutorial-ring");
        $("body").addClass("body-no-scroll");
      } else {
        adjustDarkScreenTop();
        $(".dark-screen, .hidden-controls").fadeToggle("fast");
        $("body").toggleClass("body-no-scroll");
      }

      $(".expand-item-menu-btn img").toggleClass("rotated-centered-image");
    });

    $(".expand-item-menu-btn").on("click", function () {
      const height = isItemMenuExpanded ? 0 : "100";
      $(".desktop-add-item-controls .nav-container").css(
        "max-height",
        height + "px"
      );
      setIsItemMenuExpanded(!isItemMenuExpanded);
    });

    $("#transfer-button").on("click", function () {
		$('#add-subtract-input input').val(0);
      if ($('input[name="room_inventory_id"]').val().toString(10).length > 9) {
        alert("This item needs to be synced before transfer is allowed.");
      } else {
        $(".dark-screen-top, #transfer-card").show();
		$('#add-subtract-input input').focus();
		
		//render a good user experience for them
		renderTransferData();
      }
    });
	
	$("#audit-button").on("click", function () {
		$('#add-subtract-input input').val(0);
      if ($('input[name="room_inventory_id"]').val().toString(10).length > 9) {
        alert("This item needs to be synced before audit is allowed.");
      } else {
        $(".dark-screen-top, #audit-card").show();
		$('#add-subtract-input input').focus();
		
		//we want to keep details clearly available for the user, so make notes for them:
		let itemName = $('#product_name').val();
		let itemNumber = $('#barcode-input-form-field').val();
		let itemRoom = $('#rooms option:selected').text();
		let itemUOM = $('#uom_primary_unit_type option:selected').text();
		
		let thedetails = `
				<ul>
					<li>Item Name: ${itemName} </li>
					<li>Item Number: ${itemNumber}</li>
					<li>Item Room: ${itemRoom}</li>
					<li>Item UOM: ${itemUOM}</li>
				</ul>
			`;
			
		$(".thedetails").html(thedetails);
		
		$("input[name='item-id']").val($('#room_inventory_id').val());
		$('#audit-card').find('input:text').val('');
      }
    });

    $("#add-button-in-card").on("click", function () {
      if (!$(this).hasClass("static-add-subtract-buttons")) {
        setItemQuantityAdjustment(ADD);
        updateEditQuantityCard();
        $("#adjust-quantity-card").removeClass();
        $("#adjust-quantity-card").addClass("add-active");
      }
      return false;
    });

    $("#add-quantity-button").on("click", function (e) {
      setItemQuantityAdjustment(ADD);
      $(".dark-screen-top, #adjust-quantity-card").show();
      $("body").addClass("body-no-scroll");
	  
	  $('#adjust-quantity-card h3').html('Restock Item');
	  $('#add-button-in-card').show();
	  $('#subtract-button-in-card').hide();
	  $("input[name='item-id']").val($('#room_inventory_id').val());
	  $('#add-subtract-input input').val(0);
	  $('#add-subtract-input input input').focus();
	  
	  //we want to keep details clearly available for the user, so make notes for them:
		let itemName = $('#product_name').val();
		let itemNumber = $('#barcode-input-form-field').val();
		let itemRoom = $('#rooms option:selected').text();
		let itemUOM = $('#uom_primary_unit_type option:selected').text();
		
		let thedetails = `
				<ul>
					<li>Item Name: ${itemName} </li>
					<li>Item Number: ${itemNumber}</li>
					<li>Item Room: ${itemRoom}</li>
					<li>Item UOM: ${itemUOM}</li>
				</ul>
			`;
			
		$(".thedetails").html(thedetails);
      
	  let theaction = `<input name="actiontype" id="actiontypejs" type="hidden" value="addition" />`;
	  
	  $(".theaction").html(theaction);
	  
      $("#adjust-quantity-card").addClass("add-active");
      return false;
    });

    $("#subtract-button-in-card").on("click", function () {
      if (!$(this).hasClass("static-add-subtract-buttons")) {
        setItemQuantityAdjustment(SUBTRACT);
        updateEditQuantityCard();
        $("#adjust-quantity-card").removeClass();
        $("#adjust-quantity-card").addClass("subtract-active");
      }
      return false;
    });
    $("#subtract-quantity-button").on("click", function () {
      setItemQuantityAdjustment(SUBTRACT);
      $(".dark-screen-top, #adjust-quantity-card").show();
      $("body").addClass("body-no-scroll");
	  
	  $('#adjust-quantity-card h3').html('Pull Item');
	  $('#add-button-in-card').hide();
	  $('#subtract-button-in-card').show();
	  $("input[name='item-id']").val($('#room_inventory_id').val());
	  $('#add-subtract-input input').val(0);
	  $('#add-subtract-input input').focus();
	  
	  let theaction = `<div class="input-wrapper mb-0">
				<label for="actiontypejs">Action Type</label>
				<select name="actiontype" id="actiontypejs">
					<option value="general">General Use</option>
					<option value="waste">Waste</option>
				</select>
			</div>`;
			
		$(".theaction").html(theaction);
		
		//we want to keep details clearly available for the user, so make notes for them:
		let itemName = $('#product_name').val();
		let itemNumber = $('#barcode-input-form-field').val();
		let itemRoom = $('#rooms option:selected').text();
		let itemUOM = $('#uom_primary_unit_type option:selected').text();
		
		let thedetails = `
				<ul>
					<li>Item Name: ${itemName} </li>
					<li>Item Number: ${itemNumber}</li>
					<li>Item Room: ${itemRoom}</li>
					<li>Item UOM: ${itemUOM}</li>
				</ul>
			`;
			
		$(".thedetails").html(thedetails);
	  
      $("#adjust-quantity-card").addClass("subtract-active");
      return false;
    });
	
	$('input[name="transfer_quantity"]').on("keyup", function(e){
		if($(this).val() < 0){
			$(this).val(0);
		}
	});
	$('input[name="transfer_quantity"]').on("change", function(e){
		if($(this).val() < 0){
			$(this).val(0);
		}
	});
	
	$("input[name='changejs']").on("focus", function(e){
		$(this).val('');
	});
    $("input[name='changejs']").on("keyup", function(e){
		let error = false;
		$('.pullError').remove();
		
		let inputValue = $(this).val();
			let maxValue = $("input[name='quantityjs']").val();
			
		if(inputValue < 0 && inputValue != ''){
			$(this).val(0);
		}
		
		if($('#adjust-quantity-card').hasClass('subtract-active')){
			if(parseInt(inputValue) > parseInt(maxValue)){
				error = "Cannot exceed inventory in room"; 
				$('.thedetails ul').append('<li class="pullError">'+error+'</li>');
			}
		}
		
		updateEditQuantityCard();
    });

    $("#adjust-quantity-card #submit").on("click", () => {
      
      const newValue = $("input[name='newamountjs']").val();
      const actionType = $("#actiontypejs").val();
	  const changeValue = $("input[name='changejs']").val();
	  
	  if(changeValue == ''){
		 $('#add-subtract-input').prepend('<span class="response error">Quantity Required</span>');
		 return false;
	  }
	  
      $("input[name='quantity']").val(newValue);
      $("input[name='actiontype']").val(actionType);
      $(".dark-screen-top, #adjust-quantity-card").hide();
      $("body").removeClass("body-no-scroll");
      $("#adjust-quantity-card").removeClass();
    });

    $("#audit-card #submit-audit").on("click", () => {
      $(".dark-screen-top, #audit-card").hide();
      $("body").removeClass("body-no-scroll");
    });

    $("#audit-card #audit-return-to-inventory").on("click", (e) => {
      e.preventDefault();
      $(".dark-screen-top, #audit-card").hide();
      $("body").removeClass("body-no-scroll");
    });

    $("#audit-card #audit-save-next").on("click", () => {
      $(".dark-screen-top, #audit-card").hide();
      $("body").removeClass("body-no-scroll");
    });

    $("#close-quantity-adjust, #close-transfer-card, #close-audit-card").on(
      "click",
      function (e) {
		  e.preventDefault();
        $(
          ".dark-screen-top, #adjust-quantity-card, #transfer-card, #audit-card"
        ).hide();
        $("body").removeClass("body-no-scroll");
        $("#adjust-quantity-card").removeClass();
      }
    );

    $("#main-menu, .expanded-menu-container").on("click", function () {
      $(".expanded-menu-container").toggle();
      $("body").toggleClass("body-no-scroll");
    });
    $(".expanded-menu").on("click", (e) => {
      e.stopPropagation();
    });
	
	$('.unfinished-form').on("submit", (e) => {
		e.preventDefault();
		alert('Development is actively working on this feature and it will be available soon. Your data is ready to save.');
	});

    $("#filter-button").on("click", () => {
      if ($("#filter-overlay").is(":hidden")) {
        $("#filter-overlay").show();
        $(window).scrollTop(0);
        $("body").addClass("body-no-scroll");
        $(".desktop-add-item-controls").css("z-index", "0");
        adjustDarkScreenTop();
        $(".dark-screen").show();
      }
    });
    $("#filter-close, .filter-submit").on("click", function () {
      hideFilter();
    });
    $(".dark-screen").on("click", () => {
      if ($("#filter-overlay").is(":visible")) {
        hideFilter();
      }
    });

    function hideFilter() {
      $("#filter-overlay").hide();
      $("body").toggleClass("body-no-scroll");
      $(".dark-screen").hide();
      $(".desktop-add-item-controls").css("z-index", "1");
    }

	$(".open-barcode-scanner").on("click", function () {
		$("#barcode-scanner").show();
		$("#barcode-scanner").css('top',0);
		$("#pwa__header").hide();
		$("body").addClass("body-no-scroll");
		loadCamera(); 
	});

    $("#close-barcode-scanner").on("click", function () {
      $("#barcode-scanner").hide();
      $("#pwa__header").show();
      $("body").removeClass("body-no-scroll");
      Quagga.stop();
    });

    $(document).keyup(function (event) {
      if ($("#barcode-scanned").is(":focus") && event.key === "Enter") {
        barcodeReaderExecute();
      }
      if (
        $("#desktop-search .main-search").is(":focus") &&
        event.key === "Enter"
      ) {
        mainSearchExecute();
      }
      if (
        $(".mobile-search .main-search").is(":focus") &&
        event.key === "Enter"
      ) {
        mainSearchExecute(true);
      }
    });
    $("#confirm-barcode").on("click", () => {
      barcodeReaderExecute();
    });
    $("#desktop-search .main-search-submit").on("click", () => {
      mainSearchExecute();
    });
    $(".mobile-search .main-search-submit").on("click", () => {
      mainSearchExecute(true);
    });
    $("#mobile-search").on("click", () => {
      $(".mobile-search").toggleClass("mobile-visible");
    });

    function barcodeReaderExecute() {
      if (
        $(location).attr("pathname") === "/edit-item" ||
        $(location).attr("pathname") === "/add-item"
      ) {
        if ($("#barcode-input-form-field").length) {
          if ($(location).attr("pathname") === "/add-item") {
            $("#barcode-type").val($("#barcode-scanned-type").val());
          }
          $("#barcode-input-form-field").val($("#barcode-scanned").val());
		  smartLookUp(true);
        }
        $("#barcode-scanner").hide();
        $("footer, #pwa__header, .add-item-controls").show();
        $("body").removeClass("body-no-scroll");
        //Quagga.stop();
      } else {
        const searchTerm = $("#barcode-scanned").val();
        //Quagga.stop();
		$('.main-search').val(searchTerm);
		
		$("#barcode-scanner").hide();
        $("footer, #pwa__header, .add-item-controls").show();
        $("body").removeClass("body-no-scroll");
		$('.main-search-submit').click();
       // document.location = "/items?search=" + searchTerm;
      }
    }

    // Add item image upload
    $(".item-image").on("click", function () {
      $("#item-image-input").click();
    });

    function readURL(input) {
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = function (e) {
          $(".item-image")
            .css("background-image", "url(" + e.target.result + ")")
            .css("background-size", "cover");
        };
        reader.readAsDataURL(input.files[0]); // convert to base64 string
      }
    }
    $("#item-image-input").change(function () {
      readURL(this);
    });
    // Expand/Shrink Side nav
    if (getCookie("menu_setting") === "open") {
      $("body").removeClass("collapsed-side-nav");
    } else {
      $("body").addClass("collapsed-side-nav");
    }
    $(".expanded-menu-button").click(function () {
      if ($(window).width() >= 1025) {
        if (
          getCookie("menu_setting") === "closed" ||
          $("body").hasClass("collapsed-side-nav")
        ) {
          $("body").removeClass("collapsed-side-nav");
          document.cookie = "menu_setting=open";
        } else {
          $("body").addClass("collapsed-side-nav");
          document.cookie = "menu_setting=closed";
        }
      }
    });

    loginHandler();
	
	$(".transaction-comment-header").click(function () {
		if($(this).siblings('#transaction-comment').hasClass('open')){
			$(this).siblings('#transaction-comment').removeClass('open');
			$(this).siblings('#transaction-comment').hide();
			$(this).siblings('.transaction-comment .charlimitcount').hide();
		}else{
			$(this).siblings('#transaction-comment').addClass('open');
			$(this).siblings('#transaction-comment').show();
			$(this).siblings('.transaction-comment .charlimitcount').show();
		}
    });
    // Form submission through JS
    $("form#adjust-quantity-card, form#audit-card").on("submit", async (e) => {
      e.preventDefault();
      $("#main-loading-spinner").fadeIn();

      const formElement = e.currentTarget;
      const form_data = new FormData(formElement);
      const new_quantity = form_data.get("newamountjs");
      const room_inventory_id = form_data.get("item-id");
	  let data = {
		  'success': false,
		  'message': 'Failed to connect to server. Data will be synced when a network connection returns',
	  };
	  
	  //console.log(new_quantity);
	  //console.log(room_inventory_id);
	  
	  try{
		  const request = await fetch("/api/edit-quantity", {
			method: "POST",
			body: form_data,
		  });
		  let data = await request.json();
	  }catch(error){
		  
	  }
	  
      if (data.message) {
        const notification = {
          status: data.status,
          message: data.message,
        };
        if (data.status === "success") {
          sessionStorage.setItem("notification", JSON.stringify(notification));
        } else if (data.status === "failed") {
          displayNotification(notification);
          $("#main-loading-spinner").hide();
          return;
        }
      }
      const room_inventory = await SktDb.getRecord(
        "room-inventory",
        room_inventory_id
      );
      room_inventory.quantity = new_quantity;
      room_inventory.update_time = new Date().toMysqlFormat();
      await SktDb.add(room_inventory, "room-inventory");
      if (window.redirectLocation) {
        window.location.href = window.redirectLocation;
      } else {
        location.reload();
      }
	  
	  $("#main-loading-spinner").fadeOut();
    });

    if ($(".location-and-room-select").length) {
      setUpLocationAndRoomSelection();
    }
    $("#clear-base-item").on("click", () => {
      $(
        "input[name='uom_secondary_unit_type'], input[name='uom_primary_unit_type'], input[name='uom_secondary_unit_qty'], input[name='uom_primary_unit_qty'], input[name='product_name'], input[name='product_description'], input[name='barcode'], input[name='barcode_autofill_id']"
      ).val("");
      $("input[name='barcode_autofill_id']").val(0);
      $(
        "input[name='barcode'], input[name='uom_primary_unit_qty'], input[name='uom_secondary_unit_qty'], input[name='uom_primary_unit_type'], input[name='uom_secondary_unit_type'], input[name='product_description'], input[name='product_name']"
      ).removeClass("locked-input");
      $(
        "input[name='barcode'], input[name='uom_primary_unit_qty'], input[name='uom_secondary_unit_qty'], input[name='uom_primary_unit_type'], input[name='uom_secondary_unit_type'], input[name='product_description'], input[name='product_name']"
      ).prop("readonly", false);
      $(".input-icons .open-barcode-scanner").show();
      $('.item-image').css('background', `url(/resources/img/add_a_photo-24px.svg) no-repeat center`);
      $('.item-image').css('background-size', `50%`);
      $('.item-image').css('background-color', `#F7F7F7`);
      $('#item-image-input').val = '/resources/img/add_a_photo-24px.svg';
      $('#text-image-url-save').val('');
      $("#clear-base-item").hide();
    });

    $("#download-report").on("click", (e) => {
      e.preventDefault();
		var path = window.location.pathname;
		var page = path.split("/").pop();
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = mm + '-' + dd + '-' + yyyy;

      console.log(window.reportItems);

      const csv = JSON2CSV(window.reportItems);
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = page + "-" + today + ".csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });

    $("#logout-section a").on("click", async (e) => {
		
		e.preventDefault();
		//allow multiple login clicks to force a logout
		if(!$(this).hasClass('bypass')){
			$(this).addClass('bypass');
			
			//check if we have any failed posts
			let failedposts = await SktDb.readAll('failedposts');
			console.log(failedposts);
			if(failedposts.length > 0){
				const notification = {
					status: "error",
					message: "You have unsynced data. To sync this data, regain an internet connection and navigate to any page. Logging out again will close your session and clear the data."
				}
				displayNotification(notification);
				return false;
			}else{
				document.cookie = "sync_data= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
				document.cookie =
				"userdetails= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
				document.cookie =
				"refresh_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
				document.cookie =
				"access_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
				document.cookie =
				"active_location= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
				window.location = "/logout";
			}
		//bypass allows logout
		}else{
			document.cookie = "sync_data= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"userdetails= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"refresh_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"access_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"active_location= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			window.location = "/logout";
		}
		
		let failedposts = await SktDb.readAll('failedposts');
		console.log(failedposts);
		if(failedposts.length > 0){
			alert('You have unsynced data. To sync this data, regain an internet connection and navigate to any page.');
			return false;
		}else{
			document.cookie = "sync_data= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"userdetails= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"refresh_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"access_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			document.cookie =
			"active_location= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
			window.location = "/logout";
		}
		
    });

    function getAnchor() {
      const currentUrl = document.URL;
      const urlParts = currentUrl.split("#");

      return urlParts.length > 1 ? urlParts[1] : null;
    }

    if (getAnchor() == "reports") {
      $(".menu-content").removeClass("active");
      $(".open-close-icon").html('<img src="/resources/img/iconmonstr-angel-up-circle-thin-32.png" alt="Close Menu">');

      $("#report-menu").addClass("active");
      $("#report-open-close-icon").html('<img src="/resources/img/iconmonstr-angel-down-circle-thin-32.png" alt="Open Menu">');
      // $("#menu-content").removeClass('active')
    }

    // Report Redirect Button
    // When clicked, page will redirect to home page and open report button
    $("#report-redirect").on("click", (e) => {
      if (window.location.pathname == "/home") {
        window.location.replace("/home#reports");
        window.location.reload();
      }
    });
	
	
	//manage user button
	$(".manage-user").on("click", function(e){
		if ($(this).siblings('.manage-user-panel').hasClass('active')) {
			$('.manage-user-panel').removeClass('active');
		}else{
			$('.manage-user-panel').removeClass('active');
			$(this).siblings('.manage-user-panel').addClass('active');
		}
    });
	$('.manage-user-panel .close-icon').on("click", function(e){
		$('.manage-user-panel').removeClass('active');
	});
	
	//subscription page switcher
	$("#subscription_id").on("change", (e) => {
		var membership_id = $("#subscription_id").val();
		$('.membership').removeClass('active');
		$('.membership-'+membership_id).addClass('active');
	});
	
	//close PHP notifications
	$("#close-notification").on("click", (e) => {
		$('.notification').remove();
	});
	
	$(".charlimit").each(function(){
		let charValue = $(this).val().length;
		let charMax = $(this).data('limit');
		$(this).parent().append(`<div class="charlimitcount small">${charValue}/${charMax}</div>`);
	});

	$(".charlimit").on('keyup',function(e){
		let charValueKey = $(this).val().length;
		let charMaxKey = $(this).data('limit');
		let limitValue = $(this).val()
		
		if(charValueKey > charMaxKey){
			limitValue = limitValue.slice(0, charMaxKey);
			$(this).val(limitValue);
			
			charValueKey = charMaxKey;
		}
		
		$(this).siblings('.charlimitcount').html(`${charValueKey}/${charMaxKey}`);
	});
	
	
	$('#excel-upload').on('change', function(e){
		e.preventDefault();
		console.log('Excel document changed');
		
		$('#verified').val('0');
		$('#submit-excel').val('Verify');
		$('#reset-excel').hide();
		$('#feedback').html('');
		$('#feedback').removeClass('success');
		$('#feedback').removeClass('error');
	});
	
	$('#reset-excel').on('click', function(e){
		e.preventDefault();
		console.log('Forced Reset');
		
		$('#verified').val('0');
		$('#submit-excel').val('Verify');
		$('#reset-excel').hide();
		$('#feedback').html('');
		$('#feedback').removeClass('success');
		$('#feedback').removeClass('error');
	});
	
	$('#excel-upload').on('submit', function(e){
		e.preventDefault();
		
		$('#main-loading-spinner').show();
		var data = new FormData($('#excel-upload')[0]);
		
		$.ajax({
			url: '/onlinerequest/excel-to-db',
			data: data,
			dataType: 'json',
			cache: false,
			contentType: false,
			processData: false,
			method: 'POST',
			type: 'POST', // For jQuery < 1.9
			success: function(data){
				console.log(data);
				
				$('#main-loading-spinner').hide();
				document.getElementById('feedback').className = '';
				
				//add the response data
				if(data.error){
					$('#feedback').addClass('error');
					if(data.warning == ''){
						$('#feedback').html(data.message);
					}else{
						$('#feedback').html(data.message + '<span class="warning warning-text">WARNING: '+data.warning+'</span>');
					}
				}else{
					$('#feedback').addClass('success');
					if(data.warning == '' || typeof(data.warning) == 'undefined'){
						$('#feedback').html(data.message);
					}else{
						$('#feedback').html(data.message + '<span class="warning warning-text">WARNING: '+data.warning+'</span>');
					}
					$('#submit-excel').val('Submit')
					$('#verified').val('1');
					$('#reset-excel').show();
				}
			}
		});
	});
	
	//********** PHONE FORMATTING ***********//
		runPhone()
		
		$('#phone').keyup(function(e) {
			
			if(e.keyCode != 8){
				if(!isNaN(String.fromCharCode(event.which)) || event.which > 95){
					runPhone();
					$('#phone').focus();
				}else{
					let phone = $('#phone').val();
					phone = phone.slice(0, -1);
					$('#phone').val(phone);
				}
			}
		});
		
		function runPhone(){
			let data = $('#phone').val();
			let phone = formatPhoneNumber(data);
			$('#phone').val(phone);
		}
		
		function formatPhoneNumber(phoneNumberString) {
			var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
			
			if(cleaned.length == 3){
				var match = cleaned.match(/^(\d{3})$/);
				return '(' + match[1] + ') ';
			}
			else if(cleaned.length == 6){
				var match = cleaned.match(/^(\d{3})(\d{3})$/);
				return '(' + match[1] + ') ' + match[2] + '-';
			}
			else if(cleaned.length == 10){
				var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
				return '(' + match[1] + ') ' + match[2] + '-' + match[3];
			}
			else{
				return phoneNumberString;
			}
			
		}
	
		//we have loading system that could trigger -- we want to watch for it and redirect after the rest of the scripts have run
		var pathname = window.location.pathname;
		if (pathname === "/load-cache") {
			//push them to a new page
			const notification = {
				status: "success",
				message: "Cache Rebuilt"
			}
			displayNotification(notification);
			window.location.href = "/";
		}
	});
  
	
}
