export default async function isOnline () {
	console.log('Checking if the system is online...');
	
	//The most reliable way to check if we're online is to make an API request.
	//So, we're going to ping our own back-end.
	try {
		const ping = await fetch("/onlinerequest/ping");
		if(ping.status >= 200 && ping.status < 300){
			//status responses between 200 and 300 mean the back-end was able to respond
			//online = true
			console.log('System is Online');
			return true; 
		}else{
			//something like a status 404 or 500 are bad as well
			//it means something is messed up with the back-end and we should treat it like we're offline
			//online = false (default value)
			console.log('System is Offline');
			return false; 
		}
	} catch (err) {
		//any error is bad
		//online = false (default value)
		console.log('System is Offline');
		return false; 
	}
	
}
