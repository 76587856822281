import getLocationsAndRoomsForHTML from './getLocationsAndRoomsForHTML'
import getCookie from './getCookie'
import SktDb from './sktDb'
import setLongLastingCookie from './setLongLastingCookie'

export default async function setUpLocationName () {
  console.log('locations running');
  const locationOptions = await getLocationsAndRoomsForHTML(true)
  let activeLocationIndex = 0
  $('#page-name #location').append(locationOptions)
  $('#location-options').append(locationOptions)

  if (getCookie('active_location') === '' || typeof getCookie('active_location') === 'undefined') {
    const locations = await SktDb.readAll('location')
	let tempLocation = "all";
    setLongLastingCookie('active_location', JSON.stringify(tempLocation))
  } else {
    activeLocationIndex = Number(JSON.parse(getCookie('active_location')).selectIndex)
  }

  $('#page-name #location option').eq(activeLocationIndex).prop('selected', true)
  $('#location-options option').eq(activeLocationIndex).prop('selected', true)
}
