import SktDb from './sktDb'
import setLongLastingCookie from './setLongLastingCookie'
import buildSyncRequirements from './buildSyncRequirements'

export default async function syncDown () {
  const response = await fetch('/api/sync-data')
  const data = await response.json()
  if (data.status === 'failed') {
    return
  }

  const sync_requirements = await buildSyncRequirements(data)

  await Promise.all(sync_requirements.map(async (element) => {
    try {
      const response = await fetch('/api/sync-down-' + element.table + '?' + $.param(element))
      if (!response.ok) {
        throw Error('Response Failed')
      }
      const data = await response.json()
	  
	  console.log(data);
      await Promise.all(data.results.map(async (value) => {
		await SktDb.add(value, data.table)
      }))
    } catch (e) {
      console.log('Sync down failed: ', e)
    }
  }))

  setLongLastingCookie('sync_data', data)
}
