import SktDb from './sktDb';
import getCookie from './getCookie';
import { uniqBy } from 'lodash';

export default async function countSKU(localCount = undefined) {
	//declare some variables
	let totalSKU;
	let roomSKU;

	// Limit the counter to only the items page
	if (window.location.pathname !== '/items') {
		return;
	}

	let items = await SktDb.readAll('room-inventory');

	await Promise.all(
		items.map(async (item) => {
			const barcode = await SktDb.getRecord('barcode', item.barcode_id);
			const room = await SktDb.getRecord('room', item.room_id);
			const location = await SktDb.getRecord('location', room.location_id);
			const room_barcode_alert = await SktDb.getRecord(
				'room-barcode-alert',
				item.room_barcode_alert_id
			);
			
			//   item.barcode = barcode;
			item.location_id = room.location_id;
		})
	);
  
	//make a copy of all the items
	let allItems = items;
	items = uniqBy(items, 'barcode_id');
  
	//we need to trim down our items to unique barcodes
	let finalItems = Array();
	let checkItems = Array();
  
	items.forEach(function (item, index) {
		if(item.deleted == 0 && !checkItems.includes(item.barcode_id)){
			finalItems.push(item);
			checkItems.push(item.barcode_id);
		}
	});
	
	totalSKU = finalItems.length;

	// Cookies
	let active_location_or_room_cookie = JSON.parse(getCookie('active_location'));
	let active_type = '';
	let active_location_or_room_id = 'all';
	
	//it's possible for the cookie to bug out, this creates fall-back values
	if(typeof(active_location_or_room_cookie.id) != 'undefined'){
		active_location_or_room_id = active_location_or_room_cookie.id;
		active_type = active_location_or_room_cookie.type;
	}

	//if the counter exists on the page, clean it up
	try {
		$('#sku_counter').remove();
	} catch (error) {}

	//rooms and locations are unique things. So, we need to treat them a bit different.
	switch (active_type) {
		case 'room':
			//check the room_id against the current cookie value and all barcodes
			roomSKU = filter(allItems, { 
				room_id: active_location_or_room_id 
			}).length;
			
			//if a forced value was passed in, use it
			if (localCount !== undefined) {
				roomSKU = localCount;
			}
			
			//display
			$('#page-name').append(
				`<div class="tooltip" data-tooltip="Unique SKU counter for room" id="sku_counter">${roomSKU}/${totalSKU} Unique Items</div>`
			);

		break;

		case 'location':
			//check the location_id against the current cookie value and unique barcodes
			roomSKU = filter(finalItems, {
				location_id: active_location_or_room_id,
			}).length;
			
			//if a forced value was passed in, use it
			if (localCount !== undefined) {
				roomSKU = localCount;
			}
			
			//display
			$('#page-name').append(
				`<div class="tooltip" data-tooltip="Unique SKU counter for room" id="sku_counter">${roomSKU}/${totalSKU} Unique Items</div>`
			);
			
		break;
		default:

			//display
			$('#page-name').append(
			`<div class="nav-item tooltip" data-tooltip="Unique SKU counter for room" id="sku_counter">${totalSKU} Unique Items</div>`
			);

		break;
	}
}

//the typical filter method from javascript needs a bit of support here, 
//so we've got a function to handle it
function filter(arr, criteria) {
	return arr.filter(function (obj) {
		return Object.keys(criteria).every(function (c) {
			return obj[c] == criteria[c];
		});
	});
}
