/* GENERAL USE JAVASCRIPT/JQUERY */
// The comments below this are used by eslint, the camelcase line can be removed after all snake case vars have been converted
/* global Quagga */
/* eslint-disable camelcase */
import PromisePolyfill from 'promise-polyfill'
import domAssignmentFunctions from './domAssignmentFunctions'
import domReadyFunction from './domReadyFunction'
import twoDigits from './twoDigits'
import getCookie from './getCookie'

if (!window.Promise) {
  window.Promise = PromisePolyfill
}

// This just adds a method to the default date class so we can format dates for the db easier, the comment after stops eslint from flagging it
// eslint-disable-next-line no-extend-native
Date.prototype.toMysqlFormat = function () {
  return this.getUTCFullYear() + '-' + twoDigits(1 + this.getUTCMonth()) + '-' + twoDigits(this.getUTCDate()) + ' ' + twoDigits(this.getUTCHours()) + ':' + twoDigits(this.getUTCMinutes()) + ':' + twoDigits(this.getUTCSeconds())
}

// Dom Manipulation Section
domAssignmentFunctions()
domReadyFunction()
